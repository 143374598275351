<template>
  <div class="form-wrapper">
    <div class="action-button-wrapper">
      <button class="btn btn-primary saveButton" @click="save">{{ $t('Save Invoice') }}</button>
    </div>
    <el-form class="invoice-form" ref="invoice-form" :model="form" label-width="150px" label-position="left">
      <div class="create-invoice-form">
        <div class="row">
          <el-form-item :label="$t('Delivery Staff No.')" style="width: 80%">
            <el-input v-model="form.deliveryStaff"></el-input>
          </el-form-item>
        </div>
        <div class="row">
          <el-form-item class="collection-fee" :label="$t('Collection Fee')" style="width: 80%">
            <el-input v-model="form.collectionFee">
              <template slot="prepend">HK $</template>
            </el-input>
          </el-form-item>
        </div>
        <div class="row">
          <el-form-item :label="$t('Order Items')" style="width: 80%">
            <el-input type="textarea" :rows="5" v-model="form.items"></el-input>
          </el-form-item>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Common from '@/lib/common';

export default {
  name: 'InvoiceForm',
  data() {
    //Default form value
    let form = {
      deliveryStaff: '',
      collectionFee: 0,
      items: '',
    };

    return {
      form
    };
  },
  methods: {
    formatDateTime(timestamp, format) {
      return Common.formatDateTime(timestamp, format);
    },
    save() {
      this.$emit('action', this.form);
    },
  },
  watch: {
    'currentInvoice': function () {
      this.form = {
        deliveryStaff: this.currentInvoice.delivery_staff,
        collectionFee: this.currentInvoice.collection_fee,
        items: this.currentInvoice.items,
      };
    },
  },
  computed: mapState({
    currentInvoice: state => state.delivery.currentInvoice,
    langcode: state => state.langcode,
    apiUrl: state => state.apiUrl,
  }),
}
</script>

<style lang="scss">
  .action-button-wrapper {
    text-align: right;
    margin-bottom: 50px;
  }

  .form-wrapper {
    padding-left: 20px;
  }

  .invoice-form {
    padding-left: 20px;
  }
</style>
