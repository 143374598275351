<template>
    <div class="document">
      <div class="page-header">
        <h1>{{ $t('Delivery Order Management') }} > {{ $t('Delivery Invoice') }} > {{ $t('Add') }}</h1>
      </div>
      <div class="page-content">
        <div class="content-header">
          <h2>{{ $t('Add Invoice') }}</h2>
        </div>
        <InvoiceForm @action="addInvoice"/>
      </div>
    </div>
  </template>
  
  <script>
  import { mapState } from 'vuex';
  import Common from '@/lib/common';
  import Delivery from '@/lib/delivery';
  import InvoiceForm from '@/components/delivery/InvoiceForm.vue';
  
  export default {
    name: 'CreateDocument',
    components: {
        InvoiceForm
    },
    methods:{
      async addInvoice(form){
        try{
          const loginInfo = Common.getLoginInfo();
          await Delivery.createDeliveryOrder(this.apiUrl, form, loginInfo);
          this.$router.push('/delivery-invoices');
        }catch(err){
          console.log(err);
        }
      },
    },
    computed: mapState({
     langcode: state => state.langcode,
     apiUrl: state => state.apiUrl,
   }),
  }
  </script>
  
  <style scoped lang="scss">
  .content-header{
    display: flex;
    h2{
      flex: 1;
    }
    .action-button{
      margin-right: 20px;
    }
  }
  </style>